import {
  GLSettingsAppsContent,
  // IApp,
} from "@group-link-one/grouplink-components";

import { useApp } from "../useApp";

interface IAppsContent {
  setOpen?: (value: boolean) => void;
}

// type Options = {
//   apps?: IApp[] | undefined;
//   count?: number | undefined;
//   isLoading: boolean;
// };

export const AppsContent = ({ setOpen }: IAppsContent) => {
  const {
    androidOpen,
    iosOpen,
    setAndroidOpen,
    setIosOpen,
    androidSelected,
    setAndroidSelected,
    iosSelected,
    setIosSelected,
    onRegisterApp,
    apps,
    count,
    isLoading,
  } = useApp();

  // const options: Options = {
  //   apps,
  //   count,
  //   isLoading,
  // };

  const options = {
    androidOpen,
    iosOpen,
    setAndroidOpen,
    setIosOpen,
    androidSelected,
    setAndroidSelected,
    iosSelected,
    setIosSelected,
    onRegisterApp,
    apps,
    count,
    isLoading,
  };

  return <GLSettingsAppsContent setOpen={setOpen!} options={options} />;
};
