import { Fragment } from "react/jsx-runtime";

import { AuthRoutes } from "./auth.routes";
import { AuthenticatedRoutes } from "./authenticated.routes";

export const Routes = () => {
  return (
    <Fragment>
      <AuthRoutes />
      <AuthenticatedRoutes />
    </Fragment>
  );
};
