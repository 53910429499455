import {
  IOrganizationDataForm,
  TabProps,
  UpdateOrganizationFormParams,
  useI18n,
  useToast,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";

import { useOrganizationsService } from "../../../Services/organizationsService/useOrganizations";

export const useOrganizations = () => {
  const { t } = useI18n();
  const { createOrganization, updateOrganization } = useOrganizationsService();
  const { addToast } = useToast();

  const queryClient = useQueryClient();

  const sendForm = async ({ data, state, callback }: IOrganizationDataForm) => {
    createOrganization(data)
      .then((res) => {
        if (res.status === 201) {
          const activeTab = state.tabs.find(
            (tab: TabProps) => tab.id === state.activeTabId
          );
          queryClient.invalidateQueries({
            queryKey: ["get-organizations", state.search, activeTab?.active],
          });

          addToast({
            title: t("organizations.messages.organizationCreated"),
            message: t("organizations.messages.organizationDescription"),
            type: "success",
          });

          const previousCount = queryClient.getQueryData<number>([
            "smart-retail-count",
            state.search,
          ]);

          if (previousCount) {
            queryClient.setQueryData(
              ["smart-retail-count", state.search],
              previousCount + 1
            );

            state.tabs[0].count = previousCount + 1;
          }

          callback();
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });

        return err;
      });
  };

  const updateOrganizationForm = async (data: UpdateOrganizationFormParams) => {
    updateOrganization(1, data);
  };

  return {
    sendForm,
    updateOrganizationForm,
  };
};
