import {
  GL_DEVELOPERS,
  GL_SETTINGS,
  GL_SHARE,
  GLAsideItemProps,
  useGLMenuMobile,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useFlags } from "../../Context/FlagsProvider";
// import { useFlags } from "../../Context/FlagsProvider";
import { verifyIfPathIsActive } from "../../utils/verifyIfPathIsActive";

export enum MainRoutes {
  SETTINGS = "/settings/overview",
  REPORTS = "/reports",
  DEVICE_LIST = "/devices",
  EVENT_LIST = "/network/event-list",
  DEVELOPERS = "/developers",
  APPS = "/developers/apps",
}

export function useMainLayout() {
  const { t } = useI18n();
  const navigate = useNavigate();
  const { handleOpenMenuMobile, isMenuMobileOpen } = useGLMenuMobile();
  const { hasPrivileges } = useFlags();

  const navListTop: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SHARE,
      text: t("eventList.subtitle"),
      active: verifyIfPathIsActive(MainRoutes.EVENT_LIST, false),
      dropdownItems: [],
      onClick: () => navigate(MainRoutes.EVENT_LIST),
    },
    {
      id: 2,
      Icon: GL_DEVELOPERS,
      text: t("developers.title"),
      active: verifyIfPathIsActive(MainRoutes.DEVELOPERS, false),
      isToAppear: hasPrivileges(["apps_get"]),
      dropdownItems: [
        {
          id: 1,
          text: t("pages.settings.apps"),
          isToAppear: true,
          onClick: () => navigate(MainRoutes.APPS),
        },
      ],
      onClick: () => {}
    }
  ];

  const navListBottom: GLAsideItemProps[] = [
    {
      id: 1,
      Icon: GL_SETTINGS,
      text: t("pages.settings.title"),
      active: verifyIfPathIsActive(MainRoutes.SETTINGS, false),
      onClick: () => {
        navigate(MainRoutes.SETTINGS);
      },
    },
  ];

  const removeStyle = () => {
    const container = document.querySelector(".page-animated");
    if (container) {
      container.removeAttribute("style");
    }
  };

  useEffect(() => {
    if (isMenuMobileOpen) {
      handleOpenMenuMobile();
    }

    removeStyle();
  }, [navigate]);

  return { navListTop, navListBottom };
}
