import { GLHeaderNavListItemProps } from "@group-link-one/grouplink-components";
import { useMemo } from "react";

export enum DeveloperRoutes {
  DEFAULT = "/developers",
  APPS = "/developers/apps",
}

export function useDevelopersLayout() {
  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    return [
      // {
      //   id: 1,
      //   title: "Online campaigns",
      //   path: DeveloperRoutes.ONLINE_CAMPAIGN,
      // },
    ];
  }, []);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}
