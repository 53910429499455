import { useAuth } from "@group-link-one/gl-auth";
import { useGLPagination } from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useOrganizationUsersService } from "../../../../Services/organizationUsersService/useOrganizationUsers";
import { IOptions } from "../types/types";

export interface TabProps {
  id: number;
  name: string;
  active: boolean | undefined;
  count: number | undefined;
  onClick: () => void;
  cacheKey: string;
}

const IPP = 15;

export const useUsersContent = () => {
  const { user } = useAuth();
  const { actions, state } = useGLPagination();
  const { getUsers, getUsersCount, getInvites } = useOrganizationUsersService();

  const activeTab = useMemo(() => {
    return actions.getActiveTabById(state.activeTabId);
  }, [state.activeTabId, state.tabs]);

  useQuery({
    queryKey: ["total-users-count", state.search],
    queryFn: async () => {
      const totalUsersCountResponse = await getUsersCount({
        search: state.search,
        ipp: IPP,
      });

      actions.tabsActions.setCount(
        state.tabs[0],
        totalUsersCountResponse.count
      );

      return totalUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  useQuery({
    queryKey: ["total-active-users-count", state.search],
    queryFn: async () => {
      const totalActiveUsersCountResponse = await getUsersCount({
        search: state.search,
        ipp: IPP,
        active: true,
      });

      actions.tabsActions.setCount(
        state.tabs[1],
        totalActiveUsersCountResponse.count
      );

      return totalActiveUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  useQuery({
    queryKey: ["total-inactive-users-count", state.search],
    queryFn: async () => {
      const totalInactiveUsersCountResponse = await getUsersCount({
        search: state.search,
        ipp: IPP,
        active: false,
      });

      actions.tabsActions.setCount(
        state.tabs[2],
        totalInactiveUsersCountResponse.count
      );

      return totalInactiveUsersCountResponse.count;
    },
    // staleTime: 1000 * 60 * 5,
  });

  const { data: users, isLoading } = useQuery({
    queryKey: ["get-users", state.search, state.currentPage, activeTab?.active],
    queryFn: async () => {
      const { nextPageToken, optionsToStoreNextPageToken } =
        actions.getNextPageToken();

      const getUsersData = await getUsers({
        search: state.search,
        ipp: IPP,
        next_page_token: nextPageToken,
        active: activeTab?.active,
      });

      actions.setNextPageToken({
        hasMore: getUsersData.has_more,
        nextPageToken: getUsersData.next_page_token || undefined,
        optionsToStoreNextPageToken,
      });

      return getUsersData.rows;
    },
    staleTime: 1000 * 60 * 10,
  });

  const {
    data: invites,
    refetch,
    isLoading: invitesIsLoading,
  } = useQuery({
    queryKey: ["invites"],
    queryFn: async () => {
      const getInvitesData = await getInvites();

      actions.tabsActions.setCount(state.tabs[3], getInvitesData.rows.length);

      return getInvitesData.rows;
    },
    // staleTime: 1000 * 60 * 5,
  });

  function resetPagination() {
    actions.setCurrentPage(0);
  }

  useEffect(() => {
    actions.tabsActions.setOnClick(state.tabs[0], () => {
      resetPagination();
      actions.setActiveTab(1);
    });

    actions.tabsActions.setOnClick(state.tabs[1], () => {
      resetPagination();
      actions.setActiveTab(2);
    });

    actions.tabsActions.setOnClick(state.tabs[2], () => {
      resetPagination();
      actions.setActiveTab(3);
    });

    actions.tabsActions.setOnClick(state.tabs[3], () => {
      resetPagination();
      actions.setActiveTab(4);
    });
  }, []);

  const options: IOptions = {
    user,
    users,
    invitesIsLoading,
    isLoading,
    setCurrentPage: actions.setCurrentPage,
    currentPage: state.currentPage,
    tabs: state.tabs,
    activeTab,
    invites,
    state,
    refetch,
  };

  return {
    options,
  };
};
