import {
  TabProps,
  useI18n,
  useOrganizationsStore,
} from "@group-link-one/grouplink-components";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useMemo } from "react";

import { useOrganizationsService } from "../../../../Services/organizationsService/useOrganizations";

export const useOrganizationsContent = () => {
  const { t } = useI18n();
  const { state, actions } = useOrganizationsStore();
  const { getOrganizations } = useOrganizationsService();

  const activeTab = useMemo(() => {
    return state.tabs.find((tab: TabProps) => tab.id === state.activeTabId);
  }, [state.activeTabId, state.tabs]);

  const deviceOptions = [
    {
      title: t("organizations.modal.dropdown.solutions.title"),
      options: [
        {
          text: "Water",
          value: "water",
        },
        {
          text: "Gas",
          value: "gas",
        },
        {
          text: "Energy",
          value: "energy",
        },
      ],
    },
    {
      title: t("organizations.modal.dropdown.occupational_area.title"),
      options: [
        {
          text: "Individual metering company",
          value: "individualizer",
        },
        {
          text: "Supplier company",
          value: "concessionaire",
        },
        {
          text: "Factory",
          value: "factory",
        },
      ],
    },
  ];

  const sendForm = () => {};

  useQuery({
    queryKey: ["smart-retail-count", state.search],
    queryFn: async () => {
      const totalUsersCountResponse = await getOrganizations({
        use_case: "sr",
      });

      actions.tabsActions.setCount(
        state.tabs[0],
        totalUsersCountResponse.data.rows.length
      );

      return totalUsersCountResponse.data.rows.length;
    },
  });

  useQuery({
    queryKey: ["util-water-count", state.search],
    queryFn: async () => {
      const totalUsersCountResponse = await getOrganizations({
        use_case: "util_water",
      });

      actions.tabsActions.setCount(
        state.tabs[1],
        totalUsersCountResponse.data.rows.length
      );

      return totalUsersCountResponse.data.rows.length;
    },
  });

  useQuery({
    queryKey: ["track-count", state.search],
    queryFn: async () => {
      const totalUsersCountResponse = await getOrganizations({
        use_case: "track",
      });

      actions.tabsActions.setCount(
        state.tabs[2],
        totalUsersCountResponse.data.rows.length
      );

      return totalUsersCountResponse.data.rows.length;
    },
  });

  const {
    data: organizations,
    isLoading,
    refetch,
  } = useQuery({
    queryKey: ["get-organizations", state.search, activeTab?.active],
    queryFn: async () => {
      const getOrgData = await getOrganizations({
        org_name: state.search,
        use_case: activeTab?.cacheKey,
      });

      return getOrgData.data.rows;
    },
  });

  function resetPagination() {
    actions.setCurrentPage(0);
  }

  useEffect(() => {
    actions.tabsActions.setOnClick(state.tabs[0], () => {
      resetPagination();
      actions.setActiveTab(1);
    });

    actions.tabsActions.setOnClick(state.tabs[1], () => {
      resetPagination();
      actions.setActiveTab(2);
    });

    actions.tabsActions.setOnClick(state.tabs[2], () => {
      resetPagination();
      actions.setActiveTab(3);
    });
  }, []);

  useEffect(() => {
    if (state.search.length === 0) {
      actions.setActiveTab(1);
    }
  }, []);

  useEffect(() => {
    actions.setCurrentPage(0);
  }, [state.search, activeTab]);

  return {
    setCurrentPage: actions.setCurrentPage,
    currentPage: state.currentPage,
    tabs: state.tabs,
    state,
    isLoading,
    organizations,
    sendForm,
    refetch,
    activeTab,
    actions,
    deviceOptions,
  };
};
