import * as yup from "yup";

// Definição do esquema de validação
const envSchema = yup
  .object({
    NODE_ENV: yup.string().required(),
    REACT_APP_API_URL: yup.string().required(),
    REACT_APP_FB_CONFIG_JSON: yup.string().required(),
  })
  .required();

// Função de validação que também tipa o retorno
function validateEnv(env: NodeJS.ProcessEnv) {
  try {
    const validatedEnv = envSchema.validateSync(env, { abortEarly: false });
    return validatedEnv as yup.InferType<typeof envSchema>;
  } catch (error) {
    if (error instanceof yup.ValidationError) {
      error.errors.forEach((err) => console.error(err));
    }
    throw error;
  }
}

// Validação das variáveis de ambiente
export const env = validateEnv(process.env);
