import { motion } from "framer-motion";
import styled from "styled-components";

import { pageAnimation, pageTransition } from "./pageTransitionAnimation";

export const PageAnimatedContainer = styled(motion.div).attrs({
  initial: "out",
  animate: "in",
  exit: "end",
  variants: pageAnimation,
  transition: pageTransition,
})`
  flex: 1;
  height: 100%;

  display: flex;
  flex-direction: column;

  &.page-animated-in {
    transform: none !important;
  }
`;

interface PageAnimatedProps {
  children: React.ReactNode;
}

export function PageAnimated({ children }: PageAnimatedProps) {
  return (
    <PageAnimatedContainer className="page-animated">
      {children}
    </PageAnimatedContainer>
  );
}
