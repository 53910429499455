import { useAuth } from "@group-link-one/gl-auth";
import { useNavigate } from "react-router-dom";

import { useApp } from "../Apps/useApp";

export const useOverview = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { count, isLoading } = useApp();

  return {
    user,
    count,
    isLoading,
    navigate,
  };
};
