import { useI18n } from "@group-link-one/grouplink-components";

export const useWaterColumns = () => {
  const { t } = useI18n();

  return [
    {
      columnName: t("eventList.columns.device_id"),
    },
    {
      columnName: t("eventList.columns.created_at"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.sent"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.liters"),
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.alarms"),
      hasActions: false,
    },
    {
      columnName: "RSSI",
      hasActions: false,
    },
    {
      columnName: t("eventList.columns.duration"),
      hasActions: false,
    },
  ];
};
