import {
  GL_BINOCULARS,
  GL_COLORS,
  GLEmptyList,
  GLTable,
  GLTableSkeleton,
} from "@group-link-one/grouplink-components";

import { EventListCards } from "../Cards/EventListCards";
import { useEventList } from "../useEventList";

export function EventListTableContent() {
  const { events, handleScroll, isLoading, previousData, state, columns } =
    useEventList();

  return (
    <>
      {events && events?.length > 0 && !previousData ? (
        <GLTable
          onScroll={handleScroll}
          columns={columns}
          isLoading={state.isFetchingMoreEvents}
          content={<EventListCards events={events} />}
        />
      ) : isLoading ? (
        <GLTableSkeleton />
      ) : (
        <GLEmptyList
          title={state.emptyState.title}
          description={state.emptyState.description}
          icon={
            <GL_BINOCULARS size={30} fill={GL_COLORS.FONT_COLOR_DARK_GREY} />
          }
          hasBorder
        />
      )}
    </>
  );
}
