import { Transition } from "framer-motion";

export const pageAnimation = {
  in: {
    opacity: 1,
    x: 0,
  },
  out: {
    opacity: 0.5,
    x: "-100vw",
  },
  end: {
    opacity: 0.5,
    x: "100vw",
  },
};

export const pageTransition: Transition = {
  duration: 0.4,
  type: "tween",
  // ease: "backInOut",
  easings: [0.42, 0, 0.58, 1],
};
