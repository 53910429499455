import { GLSettingsOrganizations, OrganizationProvider } from "@group-link-one/grouplink-components"

import { PageAnimated } from "../../../utils/pageAnimated"
import { OrganizationContent } from "./Content/content"
import { useOrganizations } from "./useOrganizations"


export const Organizations = () => {
  const { sendForm, updateOrganizationForm } = useOrganizations()

  return (
    <PageAnimated>
      <OrganizationProvider>
        <GLSettingsOrganizations
          onCreateOrganization={sendForm}
          onUpdateOrganization={updateOrganizationForm}
        >
          <OrganizationContent />
        </GLSettingsOrganizations>
      </OrganizationProvider>
    </PageAnimated>
  )
}
