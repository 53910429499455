import { GLSettingsOrganizationsContent } from "@group-link-one/grouplink-components";

import { useOrganizationsContent } from "./useOrganizationsContent";

export const OrganizationContent = () => {
  const { tabs, isLoading, organizations, activeTab, refetch } =
    useOrganizationsContent();

  const options = {
    tabs,
    isLoading,
    organizations,
    activeTab,
    refetch,
  };

  return <GLSettingsOrganizationsContent options={options} />;
};
