import {
  TabProps,
  useGLPagination,
  useI18n,
  useToast,
  useUsersStore,
} from "@group-link-one/grouplink-components";
import { useQueryClient } from "@tanstack/react-query";

import { useOrganizationUsersService } from "../../../Services/organizationUsersService/useOrganizationUsers";
import {
  ICreateUserInvite,
  IUpdateCountWhenDeactivateUser,
  IUser,
  IUserCardInfo,
} from "./types/types";

export const useOrganizationUsers = () => {
  const { inviteUser, deactivateUser, deleteInvite, activateUser } =
    useOrganizationUsersService();
  const { addToast } = useToast();
  const { t } = useI18n();
  const { actions: usersStoreActions } = useUsersStore();

  const queryClient = useQueryClient();

  const { state: paginationStoreState } = useGLPagination();

  const createUserInvite = async ({
    name,
    last_name,
    email,
    external_roles,
  }: ICreateUserInvite) => {
    usersStoreActions.setIsLoading(true);

    inviteUser({
      greeting_name: `${name} ${last_name}`,
      emails: email,
      external_roles: [external_roles],
      // org_id: user?.org.id, // Não vamos mandar o org_id por enquanto.
      link_config: "smart",
    })
      .then((res) => {
        usersStoreActions.setOpenDetailedModal(false);

        if (res.status === 201) {
          addToast({
            title: t("users.toast.success.title"),
            message: t("users.toast.success.message"),
            type: "success",
          });

          queryClient.invalidateQueries({
            queryKey: ["invites"],
          });
        }
      })
      .catch((err) => {
        usersStoreActions.setOpenDetailedModal(false);
        const { data } = err.response;

        if (data.code === "email_already_invited_resend") {
          addToast({
            title: t("users.toast.error.title"),
            message: `${t("users.toast.error.messageOne")} ${email} ${t("users.toast.error.messageTwo")}`,
            type: "success",
          });

          return;
        }

        if (data.code === "email_already_invited_other_org") {
          addToast({
            title: t("users.messages.userAlreadyInvitedTitle"),
            message: t("users.messages.userAlreadyInvitedDescription", {
              email,
            }),
            type: "warning",
          });

          return;
        }

        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });

        return;
      })
      .finally(() => {
        usersStoreActions.setIsLoading(false);
      });
  };

  const activeUserById = async (id: number) => {
    await activateUser(id)
      .then((res) => {
        if (res.status === 202) {
          addToast({
            title: t("users.messages.userActivatedTitle"),
            message: t("users.messages.userActivatedDescription"),
            type: "success",
          });

          updateCountWhenActiveUser({
            id,
          });
        }

        if (res.status === 401) {
          addToast({
            title: t("users.messages.userNotActiveTitle"),
            message: t("users.messages.userNotActiveDescription"),
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.error(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });

        return;
      });
  };

  const deactivateUserById = async (
    id: number,
    userCardInfo: IUserCardInfo
  ) => {
    deactivateUser(id)
      .then((response) => {
        if (response.status === 202) {
          addToast({
            title: t("users.messages.userDeactivatedTitle"),
            message: `${userCardInfo.name} ${t("users.messages.userDeactivatedDescription")}`,
            type: "success",
          });

          updateCountWhenDeactivateUser({ id });
        }

        if (response.status === 404) {
          addToast({
            title: t("users.messages.userNotFoundTitle"),
            message: t("users.messages.userNotFoundDescription"),
            type: "error",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
        return err;
      });
  };

  const deleteInviteById = async (id: number) => {
    usersStoreActions.setIsDeleteInviteLoading(true);

    deleteInvite(id)
      .then((res) => {
        if (res.status === 202) {
          addToast({
            title: t("users.messages.userInviteDeletedTitle"),
            message: t("users.messages.userInviteDeletedDescription"),
            type: "success",
          });

          queryClient.invalidateQueries({
            queryKey: ["invites"],
          });

          usersStoreActions.setOpenDeleteInviteModal(false);
        }
      })
      .catch((err) => {
        console.log(err);
        addToast({
          title: t("toast.error.title"),
          message: t("toast.error.message"),
          type: "error",
        });
      })
      .finally(() => {
        usersStoreActions.setIsDeleteInviteLoading(false);
      });
  };

  function getPreviousCount() {
    const previousActiveCount = queryClient.getQueryData<number>([
      "total-active-users-count",
      paginationStoreState.search,
    ]);

    const previousInactiveCount = queryClient.getQueryData<number>([
      "total-inactive-users-count",
      paginationStoreState.search,
    ]);

    return {
      previousActiveCount,
      previousInactiveCount,
    };
  }

  function setNewUsersInViewInTabAll({
    id,
    active,
  }: IUpdateCountWhenDeactivateUser) {
    const statusActiveTab = paginationStoreState.tabs[0].active;

    const usersInView = queryClient.getQueryData<IUser[]>([
      "get-users",
      paginationStoreState.search,
      paginationStoreState.currentPage,
      statusActiveTab,
    ]);

    if (usersInView) {
      const newUsers = usersInView.map((userInView) => {
        if (userInView.id === id) {
          return {
            ...userInView,
            active,
          };
        }

        return userInView;
      });

      queryClient.setQueryData(
        [
          "get-users",
          paginationStoreState.search,
          paginationStoreState.currentPage,
          statusActiveTab,
        ],
        newUsers
      );
    }
  }

  function removeUserFromList({ id }: IUpdateCountWhenDeactivateUser) {
    const statusActiveTab = paginationStoreState.tabs.find(
      (tab: TabProps) => tab.id === paginationStoreState.activeTabId
    )?.active;

    const usersInView = queryClient.getQueryData<any[]>([
      "get-users",
      paginationStoreState.search,
      paginationStoreState.currentPage,
      statusActiveTab,
    ]);

    if (usersInView) {
      const newUsers = usersInView.filter((userInView) => {
        return userInView.id !== id;
      });

      queryClient.setQueryData(
        [
          "get-users",
          paginationStoreState.search,
          paginationStoreState.currentPage,
          statusActiveTab,
        ],
        newUsers
      );
    }
  }

  function updateCountWhenDeactivateUser({
    id,
  }: IUpdateCountWhenDeactivateUser) {
    const { previousActiveCount, previousInactiveCount } = getPreviousCount();

    if (previousActiveCount) {
      queryClient.setQueryData(
        ["total-active-users-count", paginationStoreState.search],
        previousActiveCount - 1
      );

      paginationStoreState.tabs[1].count = previousActiveCount - 1;
    }

    if (previousInactiveCount) {
      queryClient.setQueryData(
        ["total-inactive-users-count", paginationStoreState.search],
        previousInactiveCount + 1
      );

      paginationStoreState.tabs[2].count = previousInactiveCount + 1;
    }

    if (paginationStoreState.activeTabId === 1) {
      setNewUsersInViewInTabAll({ id, active: false });
    } else {
      removeUserFromList({ id });
    }
  }

  function updateCountWhenActiveUser({ id }: IUpdateCountWhenDeactivateUser) {
    const { previousActiveCount, previousInactiveCount } = getPreviousCount();

    if (previousActiveCount) {
      queryClient.setQueryData(
        ["total-active-users-count", paginationStoreState.search],
        previousActiveCount + 1
      );

      paginationStoreState.tabs[1].count = previousActiveCount + 1;
    }

    if (previousInactiveCount) {
      queryClient.setQueryData(
        ["total-inactive-users-count", paginationStoreState.search],
        previousInactiveCount - 1
      );

      paginationStoreState.tabs[2].count = previousInactiveCount - 1;
    }

    if (paginationStoreState.activeTabId === 1) {
      setNewUsersInViewInTabAll({ id, active: true });
    } else {
      removeUserFromList({ id });
    }
  }

  return {
    createUserInvite,
    deactivateUserById,
    deleteInviteById,
    activeUserById,
  };
};
