import {
  IRewritePassword,
  useI18n,
} from "@group-link-one/grouplink-components";
import { useToast } from "@group-link-one/grouplink-components";
import { useLocation, useNavigate } from "react-router-dom";

import { useForgotPasswordService } from "../../../Services/forgotPasswordService/useForgotServices";

export const useNewPassword = () => {
  const { t } = useI18n();
  const { pathname } = useLocation();
  const { rewritePassword } = useForgotPasswordService();
  const { addToast } = useToast();
  const navigate = useNavigate();

  const extractTokenFromPathname = () => {
    const tokenIndex = pathname.lastIndexOf("/");
    const token = pathname.substring(tokenIndex + 1);
    return token;
  };

  const rewrite = async ({ password }: IRewritePassword) => {
    try {
      const response = await rewritePassword({
        token: extractTokenFromPathname(),
        password,
      });

      if (response.status === 202) {
        addToast({
          title: t("updatePasswordToast.title"),
          message: t("updatePasswordToast.description"),
          type: "success",
        });
        navigate("/login");
      }
      return response;
    } catch (error: any) {
      if (
        error.response.status === 400 ||
        error.response.data.message === "token_expired| Token expired"
      ) {
        addToast({
          title: t("updatePasswordToast.error.title"),
          message: t("updatePasswordToast.error.description"),
          type: "error",
        });

        navigate("/forgot-password");

        return;
      }

      addToast({
        title: t("toast.error.title"),
        message: t("toast.error.message"),
        type: "error",
      });

      return;
    }
  };

  return {
    rewrite,
  };
};
