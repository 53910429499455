import { useAuth } from "@group-link-one/gl-auth";
import { IApp, IAppVault } from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";

export const useAppService = () => {
  const { accessToken } = useAuth();
  const { httpPrivate} = useAxiosHTTPRequest();
  const headers = { Authorization: accessToken };

  const createApp = async (data: IApp) => {
    return httpPrivate.post("/apps", data, {
      headers,
    });
  };

  const getApps = async (): Promise<IApp[]> => {
    return httpPrivate
      .get("/apps?ipp=2000", {
        headers,
      })
      .then((res) => res.data.rows);
  };

  const countApps = async (): Promise<number> => {
    return httpPrivate
      .get("/apps/count", {
        headers,
      })
      .then((res) => res.data.count);
  };

  const deleteApps = async (appId: number): Promise<void> => {
    return httpPrivate.delete(`/apps/${appId}`, {
      headers,
    });
  };

  const appsVault = async (vaultData: IAppVault) => {
    return httpPrivate.patch(
      "/organization-vault/message-center",
      vaultData,
      {
        headers,
      },
    );
  };

  return {
    createApp,
    getApps,
    countApps,
    deleteApps,
    appsVault,
  };
};
