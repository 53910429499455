import {
  IJoinUserOrganization,
  PaginateResponseProps,
} from "@group-link-one/grouplink-components";

import useAxiosHTTPRequest from "../useAxiosHTTPRequest";
import {
  InviteUserBody,
  IRequestParams,
  IUserResponse,
} from "./useOrganizationUsers.types";

interface GetUsersCountResponse {
  count: number;
}

export const useOrganizationUsersService = () => {
  const { httpPrivate } = useAxiosHTTPRequest();

  const joinOrganization = async (data: IJoinUserOrganization) => {
    return await httpPrivate.post("/join-organization/join-organization", data);
  };

  const inviteUser = async (data: InviteUserBody) => {
    return await httpPrivate.post("/organization-user/invite", data);
  };

  const getUsersCount = async ({
    active,
    search,
    ipp,
  }: IRequestParams): Promise<GetUsersCountResponse> => {
    const response = await httpPrivate.get("/organization-user/count", {
      params: {
        active,
        search,
        ipp,
      },
    });

    return response.data;
  };

  const getUsers = async ({
    active,
    next_page_token,
    ipp,
    search,
  }: IRequestParams): Promise<PaginateResponseProps<IUserResponse[]>> => {
    const response = await httpPrivate.get("/organization-user", {
      params: {
        active,
        next_page_token,
        ipp,
        search,
      },
    });

    return response.data;
  };

  const getInvites = async () => {
    const response = await httpPrivate.get("/organization-user/invite");

    return response.data;
  };

  const activateUser = async (id: number) => {
    return await httpPrivate.patch(`/organization-user/activate/${id}`);
  };

  const deactivateUser = async (id: number) => {
    return await httpPrivate.patch(`/organization-user/deactivate/${id}`);
  };

  const deleteInvite = async (id: number) => {
    return await httpPrivate.delete(`/organization-user/invite/${id}`);
  };

  return {
    inviteUser,
    getUsers,
    getInvites,
    getUsersCount,
    activateUser,
    deactivateUser,
    deleteInvite,
    joinOrganization,
  };
};
