import "./Services/remoteConfig";

import {
  GLMenuMobileProvider,
  GLModalLogoutProvider,
  GLToastProvider,
  I18n,
} from "@group-link-one/grouplink-components";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Helmet } from "react-helmet";

import FlagsProvider from "./Context/FlagsProvider";
import { useCallbackToModalLogout } from "./hooks/useCallbackToModalLogout";
import { Routes } from "./Routes/index.routes";

function App() {
  const { callbackFnToModalLogout } = useCallbackToModalLogout();

  return (
    <>
      <FlagsProvider>
        <GLModalLogoutProvider
          callbackFnToModalLogout={callbackFnToModalLogout}
        >
          <GLMenuMobileProvider>
            <GLToastProvider>
              <I18n>
                <Routes />
              </I18n>
            </GLToastProvider>
          </GLMenuMobileProvider>
        </GLModalLogoutProvider>
      </FlagsProvider>

      <ReactQueryDevtools />
      <Helmet>
        <meta
          httpEquiv="Content-Security-Policy"
          content="object-src 'none'; upgrade-insecure-requests"
        />
        <meta httpEquiv="X-Content-Type-Options" content="nosniff" />
        <meta httpEquiv="X-XSS-Protection" content="1; mode=block" />
        <meta httpEquiv="Referrer-Policy" content="no-referrer" />
        <meta
          httpEquiv="Strict-Transport-Security"
          content="max-age=31536000; includeSubDomains"
        />

        <style>
          {`
            .tsqd-main-panel {
              z-index: 99999 !important;
            }
          `}
        </style>
      </Helmet>
    </>
  );
}

export default App;
