import { GLHeaderNavListItemProps } from "@group-link-one/grouplink-components";
import { useMemo } from "react";

export function useEventListLayout() {
  const headerNavList: GLHeaderNavListItemProps[] = useMemo(() => {
    return [
    ];
  }, []);

  const headerNavItemActive = headerNavList.find(
    (navItem) => navItem.path === window.location.pathname
  );

  return {
    headerNavList,
    headerNavItemActive,
  };
}