import { EventListCardsProps } from "./EventListCards.types";
import { TrackCard } from "./Track/TrackCard";

export function EventListCards({ events }: EventListCardsProps) {
  console.log(events);

  return (
    <>
      {events?.map((event, index) => {
        return <TrackCard key={event.eventCreated + index} event={event} />;
      })}
    </>
  );
}
