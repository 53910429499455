import { useAuth } from "@group-link-one/gl-auth";
import {
  GL_COLORS,
  GL_EQUALIZER,
  GL_USER_CHECK,
  GLHeader,
  useI18n,
} from "@group-link-one/grouplink-components";
import { Outlet, useNavigate } from "react-router-dom";

import { useAuthServices } from "../../Services/authService/useAuthServices";
import { useSettingsLayout } from "./useSettingsLayout";

export function SettingsLayout() {
  const { user } = useAuth();
  const { logOut } = useAuthServices();
  const { t } = useI18n();
  const navigate = useNavigate();
  const { headerNavList, headerNavItemActive } = useSettingsLayout();

  return (
    <>
      <GLHeader
        headerNavListItems={headerNavList}
        headerNavItemActive={headerNavItemActive?.id}
        onLogOut={() => logOut({})}
        user={user!}
        menuOptions={[
          {
            icon: <GL_USER_CHECK size={18} fill={GL_COLORS.FONT_COLOR} />,
            text: t("topbar.userModal.profile"),
            onClick: () => navigate("/settings/overview"),
          },
          {
            icon: <GL_EQUALIZER size={18} fill={GL_COLORS.FONT_COLOR} />,
            text: t("topbar.userModal.accountSettings"),
            onClick: () => navigate("/settings/my-account"),
          },
          // {
          //   icon: (
          //     <GL_HELP
          //       size={18}
          //       fill="#58616A"
          //       fillTwo={undefined}
          //       fillOne={undefined}
          //     />
          //   ),
          //   text: "Help center",
          //   onClick: () => navigate("/help-center"),
          // },
        ]}
      />
      <Outlet />
    </>
  );
}
